.publication-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.publication {
    max-width: 1024px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.publication-figure {
    flex: 0 0 350px;
    padding: 2rem;

}

.publication-text {
    flex: 2 0 300px;
    padding: 1rem;
    max-width: 800px;
}

.authors{
    color: green;
}

.conference{
    color: #f57c00;
    font-weight: bold;
}