.nav-item{
    color: #eeeeee;
    display: block;
    padding: .5rem 1rem;
    cursor: pointer;
}

.nav-item:hover {
    color: white;
}

.active {
    color: #f57c00;
}

.active:hover {
    color: white;
}

.bg-dark{
    background-color: #424242 !important;
}