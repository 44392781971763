/*100 thin; 200 extra-light; 300 light; 400 regular; 500 medium;
600 semi bold; 700 bold; 800 extra bold; 900 black*/
.welcome-page{
    //height: 100vh;
    //min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.down-button{
    flex: 1 1;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 36px;
}

.filler{
    flex: 1 1;
    background-color: #61dafb;
}

.welcome-container{
    padding: 5rem;
    max-width: 1750px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;

    font-family: 'Exo', sans-serif;
}

.welcome-text{
    flex: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.welcome-image{
    flex: 2 200px;
    max-width: 600px;
    padding: 2rem;
}

.welcome-text-hello-container{
    flex: 1 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.welcome-hello{
    flex: 1;
    padding-right: 1vw;
}
.welcome-myname{
    flex: 1;
    white-space: nowrap;
}

.welcome-name{
    flex: 1 1;
    white-space: nowrap;
}

.welcome-course{
    flex: 1 1;
    white-space: nowrap;
}
.welcome-small{
    flex: 1 1;
}