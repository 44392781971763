.timeline-block-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.timeline-block{
    max-width: 1024px;
}

/*Timeline content*/
.timeline-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.timeline-content-image{
    flex: 0 0 200px;
}

.timeline-content-text{
    flex: 4 1 250px;
}

/*Defaults*/
.rs-timeline-item-dot{
    //background-color: blue;
}

.rs-timeline-item-dot:before{
    background-color: #757575;
}

.rs-timeline-item-tail{
    background-color: #757575;
}

.rs-timeline-item-last{
    //background-color: yellow;
}

.rs-timeline-item:last-child .rs-timeline-item-tail{
    bottom: 0px;
}


.rs-timeline-item-last .rs-timeline-item-dot:before{
    background-color: #757575;
}

/*Selected item*/
.timeline-item-selected .rs-timeline-item-dot:before {
    background-color: #f57c00;
    transform: scale(1.5);
}

.small .timeline-item-selected .rs-timeline-item-time{
    color: #f57c00;
}

.big .timeline-item-selected .rs-timeline-item-time{
    color: #f57c00;
}

.timeline-item-selected .timeline-item-icon{
    //transform: scale(1.3);
}

.MuiAccordionSummary-content{
    flex-direction: column;
}

/* Time related (as the effect of left hand site)*/
.big .rs-timeline-item-time {
    flex: 0 0 160px;
}

.big .rs-timeline-item-time{
    color: #494949;
    text-align: right;
    font-weight: bold;
}

.big .rs-timeline-item-dot{
    left: 156px;
}

.big .rs-timeline-item-tail{
    left: 160px;
}

.big .timeline-date{
    font-weight: bold;
}

.small .rs-timeline-item-time {
    flex: 0 0 40px;
}

.small .rs-timeline-item-dot{
    left: 36px;
}

.small .rs-timeline-item-tail{
    left: 40px;
}

.small .rs-timeline-item-time{
    color: #494949;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.small .timeline-date{
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-weight: bold;
}
/*custom dot*/
.rs-timeline-with-time .rs-timeline-item-dot.rs-timeline-item-custom-dot{
    //background-color: red;
    width: 100px;
    left: 100px;
}

.rs-timeline-with-time .rs-timeline-item-content{
    //background-color: #ff9e2c;
    flex: 1 1 100px;
}

/* Link */
a:link {
    color: inherit;
    text-decoration: none;
}

a:visited {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

a:active {
    color: inherit;
    text-decoration: none;
}