.contactme-block{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.contactme-address{
    flex: 2 2 300px;
    //background-color: yellow;
    padding: 1rem;
}

.contactme-links{
    flex: 1 1;
    //background-color: greenyellow;
    padding: 1rem;
}

.link-item{
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px;
    cursor: pointer;
}

.link-icon{
    flex: 0 0;
    padding-right: 20px;
}

.link-text{
    flex: 1 1;
}

/* Link */
a:link {
    color: inherit;
}

a:hover {
    text-decoration: none;
}
