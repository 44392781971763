.contacticon-container{
    /*width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: center;*/
    background-color: yellow;
}

.contact-icon{
    flex: 1;
}

.contact-space{
    background-color: red;
    flex: 1;
}